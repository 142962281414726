<template>
    <b-row>
<!--      <b-col md="6" lg="4" v-for="curUser of users" :key="`user-${curUser._id}`">-->
<!--        <wbviews-users-cards :user="curUser" /> -->
<!--      </b-col>-->
      <b-col sm="12">
        <b-button variant="success" @click="addNewUser(newUser)">Добавить</b-button>

        <b-table
            :items="users"
            :fields="fields"
            stacked="md"
            show-empty
            small
            headVariant="light"
            bordered
            responsive
            :busy="isLoading"
            :tbody-tr-class="getrowClassStatus"
        >
          <template #table-busy>
            <div class="text-center text-secondary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Загрузка...</strong>
            </div>
          </template>
          <template #empty="">
            <div role="alert" aria-live="polite"><div class="text-center my-2">Список пользователей пуст.</div></div>
          </template>
          <template #cell(status)="{item}">
            <div v-if="item.status" style="width: 100%;height: 10px; background-color: greenyellow"> </div>
            <div v-else style="width: 100%;height: 10px; background-color: red"></div>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                  id="btn-radios-1"
                  v-model="item.status"
                  :options="[{text: 'вкл', value: true},{text: 'откл', value: false}]"
                  :aria-describedby="ariaDescribedby"
                  name="outline-secondary"
                  button-variant="outline-secondary"
                  size="sm"
                  buttons
                  @change="userUpdate(item)"
              ></b-form-radio-group>
            </b-form-group>

            <a href="#" @click.prevent="userDelete(item)" class="text-danger">Удалить</a>

          </template>
          <template #cell(phone)="{item}">
            <input type="text" v-model="item.phone" class="form-control-micro" @change="userUpdate(item)"><br>
            <template v-if="item.tg">tgId - {{item.tg.user_id}}<br></template>
            <a href="https://t.me/Wb3000bot">Ссылка на бота</a>
          </template>
          <template #cell(name)="{item}">
            <input type="text" v-model="item.name" class="form-control-micro" @change="userUpdate(item)">
          </template>
          <template #cell(comment)="{item}">
            <input type="text" v-model="item.comment" class="form-control-micro" @change="userUpdate(item)">
          </template>
          <template #cell(sex)="{item}">
            <b-form-group >
              <b-form-radio v-model="item.sex" value="муж">муж</b-form-radio>
              <b-form-radio v-model="item.sex" value="жен">жен</b-form-radio>
            </b-form-group>
          </template>
          <template #cell(city)="{item}">
            <input type="text" v-model="item.city" class="form-control-micro" @change="userUpdate(item)">
          </template>
          <template #cell(PVZ)="{item}">
            <template v-if="item.PVZ">
<!--              {{item.PVZ.id}}<br>-->
              <input type="text" v-model="item.PVZ.address" class="form-control-micro" @change="userUpdate(item)" placeholder="адрес ПВЗ">
            </template>
            <template v-else>
<!--              <input type="text" v-model="newUser.PVZ.id" placeholder="id" class="form-control-micro" @change="userUpdate(item)">-->
              <input type="text" v-model="newUser.PVZ.address" class="form-control-micro" @change="userUpdate(item)" placeholder="адрес ПВЗ">
            </template>
          </template>
          <template #cell(bank)="{item}">
            <template v-if="item.bank.length>0">
              <input type="text" v-model="item.bank[0].bankName" placeholder="название банка" class="form-control-micro" @change="userUpdate(item)">
              <input type="text" v-model="item.bank[0].cardNumber" placeholder="номер карты" class="form-control-micro" @change="userUpdate(item)">
            </template>
            <template v-else>
              <input type="text" v-model="newUser.bank[0].bankName" placeholder="название банка" class="form-control-micro" @change="userUpdate(item)">
              <input type="text" v-model="newUser.bank[0].cardNumber" placeholder="номер карты" class="form-control-micro" @change="userUpdate(item)">
            </template>

          </template>
          <template #cell(courier)="{item}">
            <template v-if="item.courier">
              <b-form-radio-group
                v-model="item.courier"
                :options="courierOptions"
                @change="userUpdate(item)"
              ></b-form-radio-group>
            </template>
            <template v-else>
              <b-form-radio-group
                  v-model="newUser.courier"
                  :options="courierOptions"
                  @change="userUpdate(item)"
              ></b-form-radio-group>

            </template>
<!--            <b-form-group >-->
<!--              <b-form-radio v-model="item.courier" value="self">Самостоятельно</b-form-radio>-->
<!--              <b-form-radio v-model="item.courier" value="courier">Курьером</b-form-radio>-->
<!--              <b-form-radio v-model="item.courier" value="no">Без вывоза</b-form-radio>-->
<!--            </b-form-group>-->
          </template>
          <template #cell(lens)="{item}">
            <template v-if="item.lens">
              <input type="text" v-model="item.lens[0].name" class="form-control-micro" @change="userUpdate(item)" placeholder="название линз">
            </template>
            <template v-else>
              <input type="text" v-model="newUser.lens[0].name" class="form-control-micro" @change="userUpdate(item)" placeholder="название линз">
            </template>

          </template>
          <template #cell(myacuvue)="{item}">
            <selfusers-myacuvue :phone="item.phone" :key="'myacuvue-'+item.phone"/>

<!--            <template v-if="item.myacuvue">-->
<!--              <input type="text" v-model="item.myacuvue" class="form-control-micro" @change="userUpdate(item)" placeholder="id myacuvue">-->
<!--            </template>-->
<!--            <template v-else>-->
<!--              <input type="text" v-model="newUser.myacuvue" class="form-control-micro" @change="userUpdate(item)" placeholder="id myacuvue">-->
<!--            </template>-->
          </template>

        </b-table>
      </b-col>

    </b-row>
</template>
<style >
.form-control-micro {

}
</style>
<style scoped>
input, textarea, div {
  font-size: 10px!important;
}
</style>
<script>

import SelfusersMyacuvue from "@/WbViews/Users/components/MyAcuvue.vue";

export default {
  name: "wbviews-users-list",
  props: [],
  data: () => ({
    isLoading: true,
    users: [],
    newUser: {
      status: false, // активен ли тг пользователь
      phone: "9101112233", //
      tg:{phone_number: "",first_name: "",last_name: "",user_id:''},
      name: "имя фамилия", //
      comment: "от админа", // от кого юзер
      sex: "муж", //
      city: "Москва", //
      PVZ: {address:"", id:null}, // pvz
      bank: [{bankName:"", cardNumber:""}], // банк карта или сбп или еще что-то
      courier: "no", // вариант забора курьер или доставка
      lens: [{art:"", name:"", market:"", barcode:""}], // инфа о линзах
      myacuvue: "",
      isNew:true
    },
    fields: [
      { key: 'status', label: 'Статус', sortable: true, sortDirection: 'desc' },
      { key: 'phone', label: 'Телефон', sortable: true, sortDirection: 'desc' },
      { key: 'name', label: 'Имя', sortable: true, sortDirection: 'desc' },
      { key: 'comment', label: 'Комментарий', sortable: true, sortDirection: 'desc' },
      { key: 'sex', label: 'Пол', sortable: true, sortDirection: 'desc' },
      { key: 'city', label: 'Город', sortable: false, sortDirection: 'desc' },
      { key: 'PVZ', label: 'ПВЗ', sortable: false, sortDirection: 'desc' },
      { key: 'bank', label: 'Банк', sortable: false, sortDirection: 'desc' },
      { key: 'courier', label: 'Вывоз', sortable: false, sortDirection: 'desc' },
      { key: 'lens', label: 'Линзы', sortable: false, sortDirection: 'desc' },
      { key: 'myacuvue', label: 'MyAcuvue', sortable: false, sortDirection: 'desc' },
    ],
      isNew:false,
    courierOptions:[{value: 'self', text: 'Самостоятельно'}, {value: 'courier', text: 'Курьером'}, {value: 'no', text: 'Без вывоза'}]
  }),
  methods: {
    getrowClassStatus(item, type) {
      if (!item || type !== 'row') return
      if (item.isNew) return 'table-info'

    },
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    async getUsers() {
      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/self-users`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            console.log('get user')
            console.log(resp)
            this.isLoading=false
            if (resp.status) this.users=[...resp.items]
            if (!resp.status) {
              this.isLoading=false
              this.alertMsg('Ошибка загрузки аккаунтов', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
            }
          })
          .catch(err=>{
            this.isLoading=false
            this.alertMsg('Ошибка загрузки аккаунтов', `Ошибка сервера, попробуйте ещё раз ${err}`, 'danger')
          })
    },
    async addNewUser(newUser) {
      // if (!this.validationUser) {
      //   this.alertMsg('Новый кабинет | Ошибка', `ошибка валидации, проверьте правильно ли заполнили поля`, 'danger')
      // return false
      // }
      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/self-users`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify(newUser)
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.alertMsg('Новый кабинет | Успешно', `Добавлен новый кабинет, id: ${resp.message}`, 'success')
              this.newUser._id=resp.message
              this.users.push(newUser)
            }
            if (!resp.status) this.alertMsg('Новый кабинет | Ошибка', `ошибка сервера ${resp.message}`, 'danger')
          })
          .catch(err=>{
            this.alertMsg('Новый кабинет | Ошибка', `ошибка сервера ${err}`, 'danger')
          })
    },
    async userUpdate(newUser) {
      // if (!this.validationUser) {
      //   this.alertMsg('Новый кабинет | Ошибка', `ошибка валидации, проверьте правильно ли заполнили поля`, 'danger')
      // return false
      // }
      console.log('update user')
      console.log(newUser)
      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/self-users`, {
        method: 'PUT',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify(newUser)
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.alertMsg('Обновлен юзер | Успешно', `id: ${resp.message}`, 'success')
              // this.users.push(newUser)
            }
            if (!resp.status) this.alertMsg('Обновлен юзер | Ошибка', `ошибка сервера ${resp.message}`, 'danger')
          })
          .catch(err=>{
            this.alertMsg('Обновлен юзер | Ошибка', `ошибка сервера ${err}`, 'danger')
          })


    },
    async userDelete(user) {
      // if (!this.validationUser) {
      //   this.alertMsg('Новый кабинет | Ошибка', `ошибка валидации, проверьте правильно ли заполнили поля`, 'danger')
      // return false
      // }
      console.log('del user')
      console.log(user)
      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/self-users`, {
        method: 'DELETE',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify(user)
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.alertMsg('Удален юзер | Успешно', `id: ${resp.message}`, 'success')
              this.users=this.users.filter(item=>item._id!=user._id)
            }
            if (!resp.status) this.alertMsg('Удален юзер | Ошибка', `ошибка сервера ${resp.message}`, 'danger')
          })
          .catch(err=>{
            this.alertMsg('Удален юзер | Ошибка', `ошибка сервера ${err}`, 'danger')
          })


    },
  },
  components: {SelfusersMyacuvue},
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});
    if (dildoToken) {
      this.getUsers()
    }

  },
  computed: {
    validationUser() {
      return this.newUser.number.length==10 && this.newUser.card.number.length>=16 && this.newUser.card.balance.length>0 && this.newUser.card.mounth.length==2 && this.newUser.card.year.length==2 && this.newUser.card.code.length==3
    }
  }
};
</script>

