var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.addNewUser(_vm.newUser)}}},[_vm._v("Добавить")]),_c('b-table',{attrs:{"items":_vm.users,"fields":_vm.fields,"stacked":"md","show-empty":"","small":"","headVariant":"light","bordered":"","responsive":"","busy":_vm.isLoading,"tbody-tr-class":_vm.getrowClassStatus},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-secondary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v(" Загрузка...")])],1)]},proxy:true},{key:"empty",fn:function(){return [_c('div',{attrs:{"role":"alert","aria-live":"polite"}},[_c('div',{staticClass:"text-center my-2"},[_vm._v("Список пользователей пуст.")])])]},proxy:true},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('div',{staticStyle:{"width":"100%","height":"10px","background-color":"greenyellow"}}):_c('div',{staticStyle:{"width":"100%","height":"10px","background-color":"red"}}),_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"btn-radios-1","options":[{text: 'вкл', value: true},{text: 'откл', value: false}],"aria-describedby":ariaDescribedby,"name":"outline-secondary","button-variant":"outline-secondary","size":"sm","buttons":""},on:{"change":function($event){return _vm.userUpdate(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}}],null,true)}),_c('a',{staticClass:"text-danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.userDelete(item)}}},[_vm._v("Удалить")])]}},{key:"cell(phone)",fn:function(ref){
var item = ref.item;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.phone),expression:"item.phone"}],staticClass:"form-control-micro",attrs:{"type":"text"},domProps:{"value":(item.phone)},on:{"change":function($event){return _vm.userUpdate(item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "phone", $event.target.value)}}}),_c('br'),(item.tg)?[_vm._v("tgId - "+_vm._s(item.tg.user_id)),_c('br')]:_vm._e(),_c('a',{attrs:{"href":"https://t.me/Wb3000bot"}},[_vm._v("Ссылка на бота")])]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.name),expression:"item.name"}],staticClass:"form-control-micro",attrs:{"type":"text"},domProps:{"value":(item.name)},on:{"change":function($event){return _vm.userUpdate(item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "name", $event.target.value)}}})]}},{key:"cell(comment)",fn:function(ref){
var item = ref.item;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.comment),expression:"item.comment"}],staticClass:"form-control-micro",attrs:{"type":"text"},domProps:{"value":(item.comment)},on:{"change":function($event){return _vm.userUpdate(item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "comment", $event.target.value)}}})]}},{key:"cell(sex)",fn:function(ref){
var item = ref.item;
return [_c('b-form-group',[_c('b-form-radio',{attrs:{"value":"муж"},model:{value:(item.sex),callback:function ($$v) {_vm.$set(item, "sex", $$v)},expression:"item.sex"}},[_vm._v("муж")]),_c('b-form-radio',{attrs:{"value":"жен"},model:{value:(item.sex),callback:function ($$v) {_vm.$set(item, "sex", $$v)},expression:"item.sex"}},[_vm._v("жен")])],1)]}},{key:"cell(city)",fn:function(ref){
var item = ref.item;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.city),expression:"item.city"}],staticClass:"form-control-micro",attrs:{"type":"text"},domProps:{"value":(item.city)},on:{"change":function($event){return _vm.userUpdate(item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "city", $event.target.value)}}})]}},{key:"cell(PVZ)",fn:function(ref){
var item = ref.item;
return [(item.PVZ)?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.PVZ.address),expression:"item.PVZ.address"}],staticClass:"form-control-micro",attrs:{"type":"text","placeholder":"адрес ПВЗ"},domProps:{"value":(item.PVZ.address)},on:{"change":function($event){return _vm.userUpdate(item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(item.PVZ, "address", $event.target.value)}}})]:[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newUser.PVZ.address),expression:"newUser.PVZ.address"}],staticClass:"form-control-micro",attrs:{"type":"text","placeholder":"адрес ПВЗ"},domProps:{"value":(_vm.newUser.PVZ.address)},on:{"change":function($event){return _vm.userUpdate(item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newUser.PVZ, "address", $event.target.value)}}})]]}},{key:"cell(bank)",fn:function(ref){
var item = ref.item;
return [(item.bank.length>0)?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.bank[0].bankName),expression:"item.bank[0].bankName"}],staticClass:"form-control-micro",attrs:{"type":"text","placeholder":"название банка"},domProps:{"value":(item.bank[0].bankName)},on:{"change":function($event){return _vm.userUpdate(item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(item.bank[0], "bankName", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.bank[0].cardNumber),expression:"item.bank[0].cardNumber"}],staticClass:"form-control-micro",attrs:{"type":"text","placeholder":"номер карты"},domProps:{"value":(item.bank[0].cardNumber)},on:{"change":function($event){return _vm.userUpdate(item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(item.bank[0], "cardNumber", $event.target.value)}}})]:[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newUser.bank[0].bankName),expression:"newUser.bank[0].bankName"}],staticClass:"form-control-micro",attrs:{"type":"text","placeholder":"название банка"},domProps:{"value":(_vm.newUser.bank[0].bankName)},on:{"change":function($event){return _vm.userUpdate(item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newUser.bank[0], "bankName", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newUser.bank[0].cardNumber),expression:"newUser.bank[0].cardNumber"}],staticClass:"form-control-micro",attrs:{"type":"text","placeholder":"номер карты"},domProps:{"value":(_vm.newUser.bank[0].cardNumber)},on:{"change":function($event){return _vm.userUpdate(item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newUser.bank[0], "cardNumber", $event.target.value)}}})]]}},{key:"cell(courier)",fn:function(ref){
var item = ref.item;
return [(item.courier)?[_c('b-form-radio-group',{attrs:{"options":_vm.courierOptions},on:{"change":function($event){return _vm.userUpdate(item)}},model:{value:(item.courier),callback:function ($$v) {_vm.$set(item, "courier", $$v)},expression:"item.courier"}})]:[_c('b-form-radio-group',{attrs:{"options":_vm.courierOptions},on:{"change":function($event){return _vm.userUpdate(item)}},model:{value:(_vm.newUser.courier),callback:function ($$v) {_vm.$set(_vm.newUser, "courier", $$v)},expression:"newUser.courier"}})]]}},{key:"cell(lens)",fn:function(ref){
var item = ref.item;
return [(item.lens)?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.lens[0].name),expression:"item.lens[0].name"}],staticClass:"form-control-micro",attrs:{"type":"text","placeholder":"название линз"},domProps:{"value":(item.lens[0].name)},on:{"change":function($event){return _vm.userUpdate(item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(item.lens[0], "name", $event.target.value)}}})]:[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newUser.lens[0].name),expression:"newUser.lens[0].name"}],staticClass:"form-control-micro",attrs:{"type":"text","placeholder":"название линз"},domProps:{"value":(_vm.newUser.lens[0].name)},on:{"change":function($event){return _vm.userUpdate(item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newUser.lens[0], "name", $event.target.value)}}})]]}},{key:"cell(myacuvue)",fn:function(ref){
var item = ref.item;
return [_c('selfusers-myacuvue',{key:'myacuvue-'+item.phone,attrs:{"phone":item.phone}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }