<template>
  <b-container fluid>
    <b-row>
    <b-nav>
      <b-nav-item active href="/wbviews/">Выкупы</b-nav-item>
      <b-nav-item href="/wbviews/users">Кабинеты</b-nav-item>
      <b-nav-item href="/wbviews/cards">Карты</b-nav-item>
      <b-nav-item href="/wbviews/couriers">Курьеры</b-nav-item>
    </b-nav>
    </b-row>
    <hr>
    <b-row>
      <b-col sm="12">
        <b-row>
          <b-col sm="12">
            <wbviews-users-list />
          </b-col>

        </b-row>
      </b-col>

    </b-row>


  </b-container>
</template>

<style>
td {
  font-size: 10px;
}
th div {
  font-size: 10px;
}
.text-muted {
  color: #6c757d6e !important;
}
</style>
<script>

import WbviewsUsersList from "@/WbViews/Users/usersList";
export default {
  name: "users-main",
  data: () => ({
    isLoading: true,

  }),
  methods: {
  },
  components: { WbviewsUsersList},
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});
    // if (dildoToken) {
    //
    // }
    // this.$watch('data', function (val) {
    //   console.log('a thing changed')
    //   console.log(val)
    // }, {deep:true})
  },
  watch: {}


};
</script>

