<template>
  <b-row>
    <b-col class="pr-0">
      <template v-if="isLoading">
          <b-spinner class="align-middle"></b-spinner>
      </template>
      <template v-else>
        MyAcuvue {{myacuvueId}}<br>
        баллов: {{myacuvuePoints}}<br>
        <a href="#">Начислить?</a>
      </template>
    </b-col>
  </b-row>

</template>

<style>
input[type="number"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef!important;
  opacity: 1;
}
</style>

<script>
// import _ from "lodash"
// import dayjs from 'dayjs'
// import weekOfYear from "./node_modules/dayjs/plugin/weekOfYear.js"
// import './node_modules/dayjs/locale/ru.js'

export default {
  name: "selfusers-myacuvue",
  props: ['phone'],
  data() {
    return {
      isLoading: true,
      myacuvuePoints: 0,
      myacuvueId: null

    };
  },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});

    this.getMyAcuvueData()
  },
  methods: {
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    getMyAcuvueData() {
      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/myacuvue/${this.phone}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            console.log(`get myacuvue for ${this.phone}`)
            console.log(resp)
            this.isLoading=false
            if (resp.status) {
              this.myacuvuePoints = (resp.item) ? resp.item : 0
            }
            if (!resp.status) {
              this.myacuvuePoints = '-'
              this.myacuvueId = resp.message.desc
              // this.alertMsg('Ошибка загрузки данных myacuvue', `Ошибка сервера, попробуйте ещё раз ${resp.message.code} ${resp.message.desc} ${this.phone}`, 'danger')
            }
          })
    }

  },
  computed: {
    // dohod: function () {
    //   return Math.ceil(this.sp - this.zakup - this.sp/100*this.komsawb - this.upak)
    // }
  },
};
</script>